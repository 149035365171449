import React from "react"

class OurWide extends React.Component {

  render() {
    return (
      <section className="fine-box nftwallet pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our <span className="bluecolor">Wide - Ranging</span> NFT Wallet Development Services
            </h2>
            <p className="text-center mb-2">
            Our NFT Wallet Development services offer a fortified fortress for your valuable digital assets. With cutting-edge technology and meticulous attention to detail, we empower you to navigate the world of NFTs with our NFT wallet.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Expert Development Team</h3>
                <p className="pharagraph">
                We have an expert team of blockchain developers, proficient in implementing the latest technologies. We promise you the highest standards of security and functionality in every aspect of your NFT wallet development.
                </p>
              </div>
              <div className="square" >
                <h3>Customizable Solutions</h3>
                <p className="pharagraph">
                We know that every client has unique requirements and preferences. To satisfy and give life to your ideas, our NFT Wallet Development services offer customizable solutions to meet all your needs. We prioritize delivering solutions that align perfectly with your vision.
                </p>
              </div>
              <div className="square" >
                <h3>Immutable Blockchain Architecture</h3>
                <p className="pharagraph">
                Powered by an immutable blockchain architecture, our NFT Wallets provide a secure and tamper-proof environment for storing your users' digital assets. Further, we ensure that NFTs remain protected against any unauthorized manipulation.
                </p>
              </div>
              <div className="square" >
                <h3>Quality and Timely Services</h3>
                <p className="pharagraph">
                We always commit to delivering quality and timely services. Focusing on quality and timeliness is crucial for maintaining competitiveness and fostering long-term relationships with clients; we excel in that.
                </p>
              </div>
              <div className="square" >
                <h3>Round-the-Clock Support</h3>
                <p className="pharagraph">
                Our NFT Wallet Development services offer assistance, guidance, or services to clients any time of the day or night, 24 hours a day, 7 days a week. Irrespective of the time zone or location, we provide all in all support to you.
                </p>
              </div>
              <div className="square" >
                <h3>Budget-Friendly Projects</h3>
                <p className="pharagraph">
                We offer budget-friendly projects without compromising on quality. With our cost-effective solutions, any organization can succeed in the NFT wallet Business. Thus we deliver value while keeping costs under control for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OurWide
