import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is an NFT wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">An NFT wallet is a digital wallet that is crafted to store, manage, and make transactions with non-fungible tokens (NFTs). NFT wallet offers a secure transaction for users to trade their unique digital assets on various blockchain platforms.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How much does it cost to create an NFT Wallet?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The cost to create an NFT wallet can range from $10,000 to $20,000. However this is not the exact price, the cost may slightly vary according to several factors like development complexity, desired features, and functionalities, etc…
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                Which is the best blockchain wallet for NFT?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Some of the popular wallet options for NFTs are  MetaMask, Trust Wallet, Coinbase Wallet, etc... However the best blockchain wallet for NFTs depends upon individual preferences and needs. 
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What is the difference between an NFT wallet and a crypto wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The major difference is that an NFT (Non-Fungible Token) wallet is a specific type of crypto wallet designed to store, send, and receive NFTs. NFT wallets support the storage and management of these unique tokens and allow the users to trade, or sell NFTs on various marketplaces. Whereas a crypto wallet will allow you to store, send, and receive all cryptocurrencies including tokens and coins. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                What features should I consider when developing an NFT wallet in 2024?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">There are several features to consider while developing an NFT wallet in 2024. Some of the key features to consider include advanced security measures like multi-factor authentication and biometric login, integration with popular NFT marketplaces, and user-friendly interfaces with exclusive NFT management tools.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection